
import moment from 'moment';
import {  ref, onMounted, watch,  } from "vue";
import { ContentLoader } from 'vue-content-loader';
import store from "@/store/index";
import { getView, checklistselect} from "@/services/Concessionaria";
import AuthService from "@/services/AuthService";

export default {
  name: "deleteconcessionariaModal",
  props: {
    dadosconcessionaria2: {
      type: Object,
      required: true,
    },
  },
  components:{
	  ContentLoader
  },
  setup(props) {
  let logs = ref({});
  let consultorResponsavel = ref({});
  const userInfo = AuthService.getUsuarioAuth();
  const codNivel = userInfo.codNivel;
  const isMobile = ref(false);
  let grupoResponsavel = ref({});
  let marcaResponsavel = ref({});
  const interpolar:any = ref({
      codAtria: "",
      senha: "",
      consultorResponsavel: "",
      codGrupo: "",
      marcaResponsavel: "",
      codMarca: "",
      responsavel: "",
      nomeFantasia: "",
      razaoSocial: "",
      cnpj: "",
      endereco: "",
      telefone: "",
      email: "",
      status: "",
      contabilizarMO: "",
      mostrarPremiacao: "",
      codBasico: "",
      codIntermediario: "",
      codRecomendado: "",
      codRevisoes: "",
      codRevisoesTipo: "",
      nomePacoteFabrica: "",
      nomePacoteBasico: "",
      nomePacoteIntermediario: "",
      nomePacoteRecomendado: "",
      numeroparcelasfabrica: "",
      emailonepage: "",
      metaconversao: "",
      metaticketmedio: "",
      metaProdutos: "",
      metaFaturamento: "",
      telefoneSuporte: "",
      emailSuporte: "",
      intConcessionaria : '',
      codIntMontadora: ''
    });
  const codconsultor:any = ref([])
  let maiorVersao = ref(0);
  let codConcessionaria = ref();
  let dataTratada =  props.dadosconcessionaria2?.acampaatualizacao
	let animate = ref(true);
  let dadosconcessionaria = ref({})
  const distribuidor = store.getters.layoutConfig("distribuidor.distribuidor");
  let dominio_orcamento = store.getters.layoutConfig("distribuidor.dominio_orcamento");
  let dominio_gestao = store.getters.layoutConfig("distribuidor.dominio_gestao");
  let tipo_conexao = store.getters.layoutConfig("distribuidor.tipo_conexao");

  const hidenIten = ['stellantis']

  watch(() => props.dadosconcessionaria2, () => {   
    logs.value = props.dadosconcessionaria2?.codConcessionaria
    ListaView(props.dadosconcessionaria2?.codConcessionaria, distribuidor);  
  });

    onMounted(() => {
      qualidadeMidias(interpolar)
      let userAgent = navigator.userAgent || navigator.vendor;
      if(userAgent.match( /Android/i) || userAgent.match(/iPhone/i)) {
        isMobile.value = true;
      } else {
        isMobile.value = false;
      }
    });

    
    const codchecklist: any = ref([]);
    const nameschecklists:any = ref()
    const nomeschecklistStellantis:any = ref()
    const arr:any = ref()

    async function ListaView(codConcessionaria, distribuidor){
      animate.value = true;
      logs.value = props.dadosconcessionaria2?.codConcessionaria
			var response2 =  await getView(logs.value );
      dadosconcessionaria.value = response2
      interpolar.value = response2
      interpolar.consultorResponsavel = response2.consultorResponsavel;
      interpolar.codGrupo = response2.codGrupo
      interpolar.codMarca = response2.codMarca
      interpolar.qualidadeFoto = response2.qualidadeFoto
      interpolar.qualidadeVideo = response2.qualidadeVideo
      interpolar.dms = response2.dms
      interpolar.intConcessionaria = response2.intConcessionaria
      interpolar.usaChecklist = response2.usachecklist
      interpolar.codIntMontadora = response2.codIntMontadora
      arr.value =  interpolar.usaChecklist.toString().split(';');
      if(interpolar.usaChecklist){
        const posTdata = {'distribuidor':distribuidor}
        console.log('arr.value',  arr.value)
        let response = await checklistselect(posTdata);
        nameschecklists.value = arr.value.map(checklist => response[checklist])

        const resultado = Object.values(arr.value).map(Number).map((checklist) => {
          const objetoEncontrado = response.find((response) => response.checklist === checklist);
          return objetoEncontrado ? objetoEncontrado.nome : null;
        });
      
      nomeschecklistStellantis.value = resultado.join(', ');
      }     
    
      animate.value = false; 

    };

    function lancamentosMes(interpolar){
      window.open("/dms_venda_mesatual_v1.php?dms="+interpolar?.dms+"&codConcessionaria="+props.dadosconcessionaria2?.codConcessionaria);
    }
    
    function lancamentosAnoAnterior(interpolar){
      window.open("/dms_venda_anoanterior_v1.php?dms="+interpolar?.dms+"&codConcessionaria="+props.dadosconcessionaria2?.codConcessionaria);
    }
    
    function lancamentosAno(interpolar){
      window.open("/dms_venda_anoatual_v1.php?dms="+interpolar?.dms+"&codConcessionaria="+props.dadosconcessionaria2?.codConcessionaria);
    }
    
    function formatarData(dataTratada) {
      if (dataTratada) {
        return moment(String(dataTratada)).format('DD/MM/YYYY HH:mm')
      };
    };

    function qualidadeMidias(valueQualidade){
      if ((valueQualidade) == 30) return "Baixa"
      if ((valueQualidade) == 50) return "Média"
      if ((valueQualidade) == 70) return "Boa"
      if ((valueQualidade) == 100) return "Ótima"
      if ((valueQualidade) == 0) return "Baixa"
      if ((valueQualidade) == 1) return "Média"
      if ((valueQualidade) == 2) return "Ótima"
    }

    
    return {
      logs,
      animate,
      dominio_orcamento,
      codConcessionaria, 
      maiorVersao,
      ListaView,
      dadosconcessionaria,
      interpolar,
      formatarData,
      codconsultor,
      consultorResponsavel,
      marcaResponsavel,
      grupoResponsavel,
      lancamentosMes,
      lancamentosAnoAnterior,
      lancamentosAno,
      codNivel,
      qualidadeMidias,
      isMobile,
      moment,
      codchecklist,
      nameschecklists,
      nomeschecklistStellantis,
      arr,
      hidenIten,
      distribuidor
    };
  },
};
